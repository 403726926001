import React, { Fragment } from "react";
const ComingSoon = () => {
  return (
    <Fragment>
      <div
        style={{
          height: "100%",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>Coming Soon</h1>
      </div>
    </Fragment>
  );
};

export default ComingSoon;
